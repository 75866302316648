import React, { Component } from 'react';
import { AppContext } from '../services/context';

import TextField from '../components/form/TextField';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            error: ''
        };
    }

    tryToLogin() {
        if (this.state.login.length > 0 && this.state.password.length > 0) {
            this.context.authService
                .tryToLogin(this.state.login, this.state.password)
                .catch(error => {
                    this.setState({ error: error });
                });
        } else {
            console.log('login or password too short');
        }
    }

    render() {
        return (
            <div className='_page'>
                <div className='_modal _modal--small'>
                    <TextField
                        value={this.state.login}
                        handleChange={value => this.setState({ login: value })}
                        label='Login'
                        type='text'
                        fieldName='login'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <TextField
                        value={this.state.password}
                        handleChange={value => this.setState({ password: value })}
                        label='Hasło'
                        type='password'
                        fieldName='password'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <button className='_button-light' onClick={() => this.tryToLogin()}>Zaloguj</button>
                    <div>{this.state.error}</div>
                </div>
            </div>
        );
    }
}
Login.contextType = AppContext;

export default Login;
