import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

import ProductElement from './ProductElement';

import './ProductList.scss';

class ProductList extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.start);
    };
    render() {
        const list = this.props.products;

        return (
            <div className='product-list__container'>
                {list.length > 0 ? (
                    list.map(e => <ProductElement key={e.productId} element={e} />)
                ) : (
                    <div className='product-list__empty-list'>{this.T('noProducts')}</div>
                )}
            </div>
        );
    }
}
ProductList.contextType = AppContext;

export default ProductList;
