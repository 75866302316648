import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';

import { AppContext } from '../../../../services/context';

import './Files.scss';

class Files extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.start);
    };
    render() {
        const files = this.props.files;
        const lang = this.context.translateService.getLanguage();

        return (
            <div className='_catalogue-product__files'>
                {files.reduce((t, fg) => {
                    const tfg = fg.files.map(f => {
                        let currentFile = f.localized[lang];

                        if (!currentFile.filename && f.localized['en'].filename) {
                            currentFile = f.localized['en'];
                        }

                        if (!currentFile.filename) {
                            return null;
                        }

                        return (
                            <div className='_catalogue-product__file' key={f.fileId}>
                                <a href={appConfig.fileUrl + currentFile.filename}>
                                    <div className='_catalogue-product__file-preview'>
                                        {currentFile.previewFilename ? (
                                            <img src={appConfig.photoUrl + currentFile.previewFilename} alt='' />
                                        ) : (
                                            <div className='_catalogue-product__file-preview-empty'>
                                                insert_drive_file
                                            </div>
                                        )}
                                    </div>
                                    <div className='_catalogue-product__file-label'>{this.T(f.label)}</div>
                                </a>
                            </div>
                        );
                    });
                    return [...t, ...tfg];
                }, [])}
            </div>
        );
    }
}
Files.contextType = AppContext;

export default Files;
