import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../appTranslate.json';

import { AppContext } from '../../services/context';

import './Form.scss';

import ProductListItem from './ProductListItem';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                firstName: { value: '' },
                lastName: { value: '' },
                email: { value: '', error: '' },
                telephone: { value: '' },
                companyName: { value: '' }
            }
        };
    }
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.request.modal.form);
    };

    setFieldValue = (field, value) => {
        this.setState(prev => ({
            form: {
                ...prev.form,
                [field]: {
                    ...prev.form[field],
                    value: value
                }
            }
        }));
        if (field === 'email') {
            this.validateEmail(true);
        }
    };

    validateEmail = onlyPositive => {
        let error = '';

        const isValid = this.checkEmail();

        if (!isValid) {
            error = this.T('emailError');
        }

        if (isValid || !onlyPositive) {
            this.setState(prev => ({
                form: {
                    ...prev.form,
                    email: {
                        ...prev.form.email,
                        error: error
                    }
                }
            }));
        }

        return isValid;
    };

    checkEmail = () => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.form.email.value);
    };

    prepareData = () => {
        return {
            firstName: this.state.form.firstName.value,
            lastName: this.state.form.lastName.value,
            email: this.state.form.email.value,
            telephone: this.state.form.telephone.value,
            companyName: this.state.form.companyName.value,
            languageCode: this.context.translateService.getLanguage()
        };
    };

    onSubmit = () => {
        if (this.validateEmail()) {
            this.props.submit(this.prepareData());
        }
    };

    render() {
        return (
            <div className='request-form'>
                <div className='request-form__row'>
                    <div className='request-form__label'>{this.T('email')}</div>
                    <input
                        type='text'
                        value={this.state.form.email.value}
                        onChange={event => this.setFieldValue('email', event.target.value)}
                    />
                    <div className='request-form__error'>{this.state.form.email.error}</div>
                </div>
                <div className='request-form__row'>
                    <div className='request-form__label'>{this.T('firstName')}</div>
                    <input
                        type='text'
                        value={this.state.form.firstName.value}
                        onChange={event => this.setFieldValue('firstName', event.target.value)}
                    />
                </div>
                <div className='request-form__row'>
                    <div className='request-form__label'>{this.T('lastName')}</div>
                    <input
                        type='text'
                        value={this.state.form.lastName.value}
                        onChange={event => this.setFieldValue('lastName', event.target.value)}
                    />
                </div>
                <div className='request-form__row'>
                    <div className='request-form__label'>{this.T('telephone')}</div>
                    <input
                        type='text'
                        value={this.state.form.telephone.value}
                        onChange={event => this.setFieldValue('telephone', event.target.value)}
                    />
                </div>
                <div className='request-form__row'>
                    <div className='request-form__label'>{this.T('companyName')}</div>
                    <input
                        type='text'
                        value={this.state.form.companyName.value}
                        onChange={event => this.setFieldValue('companyName', event.target.value)}
                    />
                </div>

                <button className='request-form__submit' onClick={() => this.onSubmit()}>
                    {this.T('submit')}
                </button>
            </div>
        );
    }
}
Form.contextType = AppContext;

export default Form;
