import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Start from './views/Start';
import Product from './views/Product';

import './Main.scss';

class CatalogueMain extends Component {
    render() {
        return (
            <div className='area'>
                <div className='area-wrapper'>
                    <Switch>
                    <Route
                            path={`${this.props.match.path}/product/:productId`}
                            component={Product}
                            key={this.props.location.pathname}
                        />
                        <Route
                            path={`${this.props.match.path}/`}
                            component={Start}
                            key={this.props.location.pathname}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default CatalogueMain;
