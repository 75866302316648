import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../../appTranslate.json';

import { AppContext } from '../../../../services/context';

import './Properties.scss';

class Properties extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.product.details);
    };
    render() {
        const properties = this.props.properties;

        return (
            <div className='_catalogue-product__properties'>
                {properties.map(p => {
                    if (p.definitions.length === 0) {
                        return null;
                    }
                    return (
                        <div className='_catalogue-product__property-group' key={p.productPropertyGroupId}>
                            <div className='_catalogue-product__property-group-label'>{this.T(p.label)}</div>
                            {p.definitions.map(pp => {
                                return (
                                    <div className='_catalogue-product__property' key={pp.productPropertyDefinitionId}>
                                        <div className='_catalogue-product__property-definition-label'>
                                            {this.T(pp.label)}
                                        </div>
                                        <div className='_catalogue-product__property-content'>
                                            {this.T(pp.content.content)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}
Properties.contextType = AppContext;

export default Properties;
