import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import CatalogueSecondaryNav from './components/CatalogueSecondaryNav';

import './Main.scss';

class CatalogueMain extends Component {
    render() {
        return (
            <div className='area'>
                <div className='area-wrapper'>
                    <CatalogueSecondaryNav parentProps={this.props} />
                    <Switch>
                       
                    </Switch>
                </div>
            </div>
        );
    }
}

export default CatalogueMain;
