import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import axios from 'axios';

import appConfig from '../../appConfig.json';
import appTranslate from '../../appTranslate.json';

import { AppContext } from '../../services/context';

import './RequestModal.scss';

import ProductList from './ProductList';
import Form from './Form';

class RequestModal extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.request.modal);
    };

    prepareData = () => {
        return this.context.requestService.getProducts().map(p => ({ productId: p.productId, quantity: p.quantity }));
    };

    onSubmit = credentials => {
        console.log('will submit ');
        console.log(credentials);
        console.log(this.prepareData());

        // this.context.requestService.clearData();
        // this.context.requestService.setModal(false);

        this.sendRequest({ guest: credentials, products: this.prepareData() });
    };

    sendRequest = payload => {
        axios({
            url: appConfig.apiUrl + '/catalogue/request/new',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        })
            .then(response => {
                console.log(response);

                this.context.requestService.clearData();
                this.context.requestService.setModal(false);
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const products = this.context.requestService.getProducts();
        return (
            <div className='request-modal__container'>
                <div className='request-modal__background' />
                <div className='request-modal__modal'>
                    <div className='request-modal__wrapper'>
                        <div className='request-modal__header'>
                            <div
                                className='request-modal__header-close'
                                onClick={() => this.context.requestService.setModal(false)}
                            >
                                clear
                            </div>
                            <div className='request-modal__header-title'>{this.T('title')}</div>
                        </div>
                        <div className='request-modal__content'>
                            {products.length > 0 ? (
                                <ProductList products={products} />
                            ) : (
                                <div className='request-modal__content-no-products'>{this.T('noProducts')}</div>
                            )}
                            {products.length > 0 ? <Form submit={data => this.onSubmit(data)} /> : null}
                        </div>
                    </div>
                </div>
                ∂
            </div>
        );
    }
}
RequestModal.contextType = AppContext;

export default RequestModal;
