import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

import './CategoryChooser.scss';

class CategoryChooser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openTree: null
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.start);
    };

    openTree = id => {
        this.setState(prev => ({
            openTree: prev.openTree === id ? null : id
        }));
    };

    render() {
        const categoryTree = this.props.categoryTree;

        return (
            <div className='category-chooser__container'>
                {categoryTree.map(cm => {
                    let isSelected = cm.categoryId === this.props.categoryId;
                    let isOpen = cm.categoryId === this.state.openTree;

                    return (
                        <div className='category-chooser__parent' key={cm.categoryId}>
                            <div
                                className={`category-chooser__parent-element ${isSelected ? 'is-selected' : ''}`}
                                onClick={() => {
                                    if (cm.selectable) {
                                        this.props.setCategoryId(cm.categoryId);
                                    } else {
                                        this.openTree(cm.categoryId);
                                    }
                                }}
                            >
                                <div className='category-chooser__parent-element-title'>{this.T(cm.label)}</div>
                                <div className='category-chooser__parent-element-icon'>
                                    {cm.selectable
                                        ? isSelected
                                            ? 'done'
                                            : 'keyboard_arrow_right'
                                        : isOpen
                                        ? 'expand_less'
                                        : 'expand_more'}
                                </div>
                            </div>
                            {isOpen
                                ? cm.categories.map(ccm => {
                                      let isSelected = ccm.categoryId === this.props.categoryId;
                                      return (
                                          <div
                                              className={`category-chooser__child-element ${
                                                  isSelected ? 'is-selected' : ''
                                              }`}
                                              key={ccm.categoryId}
                                              onClick={() => this.props.setCategoryId(ccm.categoryId)}
                                          >
                                              <div className='category-chooser__child-element-title'>
                                                  {this.T(ccm.label)}
                                              </div>
                                              <div className='category-chooser__child-element-icon'>
                                                  {isSelected ? 'done' : 'keyboard_arrow_right'}
                                              </div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}
            </div>
        );
    }
}
CategoryChooser.contextType = AppContext;

export default CategoryChooser;
