import React, { Component } from 'react';
import './App.scss';

import { BrowserRouter as Router } from 'react-router-dom';

import { AppContext } from './services/context';
import { AuthService } from './services/authService';
import { RequestService } from './services/requestService';

import Login from './views/Login';

import Main from './views/Main';
import { TranslateService } from './services/translateService';

class App extends Component {
    constructor(props) {
        super(props);

        this.appContext = {
            authService: new AuthService(this),
            translateService: new TranslateService(this),
            requestService: new RequestService(this)
        };

        this.state = {
            loader: true,
            session: this.appContext.authService.loadSession(),
            language: this.appContext.translateService.getLanguage(),
            request: {
                products: [],
                modal: false
            }
        };
    }

    render() {
        return (
            <AppContext.Provider value={this.appContext}>
                <Router>
                    <Main />
                </Router>
            </AppContext.Provider>
        );
    }
}

export default App;
