import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../appConfig.json';
import appTranslate from '../../../appTranslate.json';
import Loader from '../../../components/basic/Loader';
import { AppContext } from '../../../services/context';
import axios from 'axios';

import './Start.scss';

import CategoryChooser from '../components/CategoryChooser';
import ProductList from '../components/ProductList';

class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryId: -1,
            categories: [],
            products: [],
            loader: {
                category: false,
                product: false
            }
        };
    }

    componentDidMount() {
        this.getCategories();
        this.getProducts(-1);
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.start);
    };

    setLoader = (loader, value) => {
        this.setState(prev => ({
            loader: {
                ...prev.loader,
                [loader]: value
            }
        }));
    };

    setCategoryId = id => {
        if (this.state.categoryId !== id) {
            this.setState(prev => ({
                categoryId: id
            }));

            this.getProducts(id);
        }
    };

    getCategories = () => {
        this.setLoader('category', true);

        axios({
            url: appConfig.apiUrl + '/catalogue/category/tree',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response);

                if (response.data.code === 200) {
                    let categories = [
                        {
                            categoryId: -1,
                            label: { pl: 'Popularne', en: 'Popular' },
                            selectable: true
                        },
                        ...response.data.data
                    ];

                    this.setState({
                        categories: categories
                    });

                    this.setLoader('category', false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    getProducts = categoryId => {
        let requestCategoryId = categoryId === -1 ? 12 : categoryId;

        this.setLoader('product', true);
        axios({
            url: appConfig.apiUrl + '/catalogue/product/listByCategory/' + requestCategoryId,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response);

                if (response.data.code === 200) {
                    if (this.state.categoryId === categoryId) {
                        this.setState({
                            products: response.data.data
                        });
                        this.setLoader('product', false);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        let mainLoader = null;
        if (this.state.loader.category) {
            mainLoader = <Loader greyBg={true} />;
        }

        let productLoader = null;
        if (this.state.loader.product && !this.state.loader.category) {
            productLoader = <Loader greyBg={true} />;
        }
        return (
            <div className='catalogue-start__container'>
                <div className='catalogue-start__title'>{this.T('catalogue')}</div>
                <div className='catalogue-start__viewer'>
                    {mainLoader}
                    <div className='catalogue-start__left'>
                        <CategoryChooser
                            categoryId={this.state.categoryId}
                            categoryTree={this.state.categories}
                            setCategoryId={id => this.setCategoryId(id)}
                        />
                    </div>
                    <div className='catalogue-start__right'>
                        {productLoader}
                        <ProductList products={this.state.products} />
                    </div>
                </div>
            </div>
        );
    }
}
Start.contextType = AppContext;

export default Start;
