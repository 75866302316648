import axios from 'axios';
import appConfig from '../appConfig.json';

import moment from 'moment';

export class RequestService {
    constructor(parent) {
        this.parent = parent;
    }

    getProductsCount = () => {
        return this.parent.state.request.products.length;
    };

    addProductToRequest = product => {
        this.parent.setState(prev => {
            prev.request.products.push({ ...product, quantity: 1 });
            return {
                request: {
                    ...prev.request,
                    products: prev.request.products
                }
            };
        });
    };

    removeProductFromRequest = productId => {
        const productIndex = this.parent.state.request.products.findIndex(e => e.productId === productId);

        if (productIndex > -1) {
            this.parent.setState(prev => {
                prev.request.products.splice(productIndex, 1);
                return {
                    request: {
                        ...prev.request,
                        products: prev.request.products
                    }
                };
            });
        }
    };

    isProductInRequest = productId => {
        return this.parent.state.request.products.findIndex(p => p.productId === productId) > -1;
    };

    setModal = value => {
        this.parent.setState(prev => ({ request: { ...prev.request, modal: value } }));
    };

    modalState = () => {
        return this.parent.state.request.modal;
    };

    getProducts = () => {
        return this.parent.state.request.products;
    };

    setProductQuantity = (productId, quantity) => {
        // if (!Number.isInteger(quantity) || quantity < 1) {
        //     quantity = 1;
        // }

        if (quantity !== '') {
            if (!Number.isInteger(parseInt(quantity)) || parseInt(quantity) < 1) {
                quantity = 1;
            } else {
                quantity = parseInt(quantity);
            }
        }

        this.parent.setState(prev => ({
            request: {
                ...prev.request,
                products: prev.request.products.map(p => {
                    if (p.productId === productId) {
                        p.quantity = quantity;
                    }
                    return p;
                })
            }
        }));
    };

    onProductBlur = productId => {
        this.parent.setState(prev => ({
            request: {
                ...prev.request,
                products: prev.request.products.map(p => {
                    if (p.productId === productId) {
                        if (!Number.isInteger(parseInt(p.quantity)) || parseInt(p.quantity) < 1) {
                            p.quantity = 1;
                        }
                    }
                    return p;
                })
            }
        }));
    };

    clearData = () => {
        this.parent.setState(prev => ({
            request: {
                ...prev.request,
                products: []
            }
        }));
    };
}
