import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

class CatalogueSecondaryNav extends Component {

    

    render() {
        const match = this.props.parentProps.match;

        return (
            <nav className='nav-secondary'>
                <Link to={`${match.path}/stock`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.magazine.secondaryNav.state
                    )}
                </Link>

                <Link to={`${match.path}/delivery`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.magazine.secondaryNav.delivery
                    )}
                </Link>

                <Link to={`${match.path}/order`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.magazine.secondaryNav.order
                    )}
                </Link>

                <Link to={`${match.path}/shipment`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.magazine.secondaryNav.shipments
                    )}
                </Link>
            </nav>
        );
    }
}
CatalogueSecondaryNav.contextType = AppContext;

export default CatalogueSecondaryNav;
