import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

class CatalogueProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            list: [],
            add: {
                modal: false
            }
        };
    }

    componentDidMount() {
        this.getList();
    }

    T = (prop, params) => {
        let p;

        if (typeof prop === 'object') {
            p = prop;
        }
        if (typeof prop === 'string') {
            p = prop
                .split('.')
                .reduce(
                    (obj, a) => obj[a],
                    appTranslate.areas.magazine.stock.list
                );
        }

        return this.context.translateService.getTranslation(p, params);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getList = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/magazine/stock/list',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        propertyDefinitionId: '[1]'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ list: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    addModalState = state => {
        this.setState(prevState => {
            return {
                add: {
                    ...prevState.add,
                    modal: state
                }
            };
        });
    };

    addModalClose = response => {
        console.log('addModal close');
        console.log(response);
        this.addModalState(false);
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const rows = this.state.list.map((e, index) => {
            return (
                <tr key={e.product.productId}>
                    <td>{index + 1}</td>
                    <td>{e.product.productId}</td>
                    <td>{this.T(e.product.name)}</td>
                    <td>{e.product.ean}</td>
                    <td>{e.quantity}</td>
                    <td>
                        <Link
                            to={`/magazine/stock/details/${
                                e.product.productId
                            }`}
                            className='_button-light small'
                        >
                            {this.T('details')}
                        </Link>
                    </td>
                </tr>
            );
        });

        return (
            <div className='content-basic'>
                {loader}

                <div className='content-basic__content'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <td>{this.T('no')}</td>
                                    <td>ID</td>
                                    <td>{this.T('name')}</td>
                                    <td>EAN</td>
                                    <td>{this.T('quantity')}</td>
                                    <td />
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
CatalogueProductList.contextType = AppContext;

export default CatalogueProductList;
