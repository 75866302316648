export class TranslateService {
    localStorageKey = 'GEAppCurrentLanguage';

    constructor(parent) {
        this.parent = parent;
        this.languages = ['pl', 'en'];
    }

    currentLanguage = () => this.parent.state.language;

    getEmptyObject = () => {
        return {
            pl: '',
            en: ''
        };
    };

    setLanguage = language => {
        this.parent.setState({ language: language });
        window.localStorage.setItem(this.localStorageKey, language);
    };

    getLanguage = () => {
        return window.localStorage.getItem(this.localStorageKey) || 'pl';
    }

    translationProxy = (property, params, r, baseObject) => {
        let p;

        if(typeof property === 'object') {
            p = property;
        }

        if (typeof property === 'string') {
            p = property
                .split('.')
                .reduce(
                    (obj, a) => obj[a],
                    baseObject
                );
        }

        if (r) {
            return this.getTranslationR(p, params);
        }
        return this.getTranslation(p, params);
    }

    getTranslation = (property, params, lang) => {
        
        if(!lang){
            lang = this.parent.state.language;
        }
        if(!property[lang]){
            console.log('Translate error')
            return '';
        }
        let translation = property[lang];
        if (params && params.length > 0) {
            params.forEach((p, index) => {
                let t = p;
                if(typeof p === 'object'){
                    t = this.getTranslation(p);
                }
                translation = translation.replace(`$-${index}`, t);
            });
        }

        return translation;
    };

    getTranslationR = (property, params) => {
        const lang = this.parent.state.language === 'pl' ? 'en' : 'pl';
        return this.getTranslation(property, params, lang);
    };
}

export function emptyObject(baseValue) {
    return {
        pl: baseValue,
        en: baseValue,
        error: ''
    };
}
