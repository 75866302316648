import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Nav from '../components/basic/Nav';

import CatalogueMain from '../areas/catalogue/Main';
import LoginMain from '../areas/user/Main';
import MagazineMain from '../areas/magazine/Main';
import ContractsMain from '../areas/contracts/Main';
import ShopMain from '../areas/shop/Main';

import RequestModal from '../components/requestModal/RequestModal';

import { AppContext } from '../services/context';

class Main extends Component {
    render() {
        let requestModal = null;
        if (this.context.requestService.modalState()) {
            requestModal = <RequestModal />;
        }
        return (
            <div className='app'>
                <Nav />
                <Switch>
                    <Route path='/catalogue' component={CatalogueMain} />
                    <Route path='/login' component={LoginMain} />
                    <Redirect from='/' to='/catalogue' />
                </Switch>
                {requestModal}
            </div>
        );
    }
}
Main.contextType = AppContext;

export default Main;
