import React, { Component } from 'react';

export class FieldBase extends Component {
    onInput = (value, language) => {
        if (this.props.handleChange) {
            this.props.handleChange(value, language);
        }
        if (this.props.onInput) {
            this.props.onInput(value, language);
        }
        if (this.props.onChange) {
            this.props.onChange(value, language);
        }
    };

    onFocus = language => {
        if (this.props.onFocus) {
            this.props.onFocus(language);
        }
    };

    onBlur = language => {
        if (this.props.onBlur) {
            this.props.onBlur(language);
        }
    };

    render() {
        return <div />;
    }
}
