import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import StockList from './StockList';
import StockDetails from './StockDetails';
// import CatalogueProducyDetails from './CatalogueProductDetails';

export default class StockMain extends Component {
    render() {
        return (
            <Switch>
                {/* <Route
                            path={`${this.props.match.path}/:productId/details`}
                            component={CatalogueCategoriesList}
                        /> */}
                <Route
                    path={`${this.props.match.path}/details/:productId`}
                    component={StockDetails}
                    key={this.props.location.pathname}
                />
                <Route
                    path={`${this.props.match.path}`}
                    component={StockList}
                />
            </Switch>
        );
    }
}
