import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../appTranslate.json';

import { AppContext } from '../../services/context';

import './ProductList.scss';

import ProductListItem from './ProductListItem';

class RequestModal extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.request.modal);
    };

    render() {
        return (
            <div className='request-product-list'>
                {this.props.products.map(e => (
                    <ProductListItem item={e} key={e.productId} />
                ))}
            </div>
        );
    }
}
RequestModal.contextType = AppContext;

export default RequestModal;
