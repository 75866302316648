import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../appConfig.json';
import appTranslate from '../../../appTranslate.json';
import Loader from '../../../components/basic/Loader';
import { AppContext } from '../../../services/context';
import axios from 'axios';

import './Product.scss';

import Properties from '../components/Product/Properties';
import Files from '../components/Product/Files';
import Binded from '../components/Product/Binded';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            loader: false,
            openTree: {
                binding: null,
                main: null,
                secondary: null
            }
        };
    }

    productId = parseInt(this.props.match.params.productId);

    componentDidMount() {
        this.getProduct();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.catalogue.product.details
        );
    };

    setLoader = value => {
        this.setState({
            loader: value
        });
    };

    openTree = (level, id) => {
        this.setState(prev => ({
            openTree: {
                ...prev.openTree,
                [level]: prev.openTree[level] === id ? null : id
            }
        }));
    };

    getProduct = () => {
        this.setLoader(true);

        axios({
            url: appConfig.apiUrl + '/catalogue/product/details/' + this.productId,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response);

                if (response.data.code === 200) {
                    this.setState({
                        product: response.data.data,
                        loader: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        if (!this.state.product || this.state.loader) {
            return (
                <div className='_catalogue-product'>
                    <Loader />
                </div>
            );
        }

        const product = this.state.product;

        const photo = product.photos.find(p => p.isMain);

        if (!photo && product.photos.length > 0) {
            photo = product.photos[0];
        }

        return (
            <div className='_catalogue-product'>
                <div className='_catalogue-product__photo'>
                    {photo ? (
                        <img src={appConfig.photoUrl + photo.filename} alt='' />
                    ) : (
                        <div className='_catalogue-product__photo-empty'>insert_photo</div>
                    )}
                </div>
                <div className='_catalogue-product__ean'>{product.ean}</div>
                <div className='_catalogue-product__name'>{this.T(product.productName)}</div>
                <div className='_catalogue-product__type'>{this.T(product.type.label)}</div>
                <Properties properties={product.properties} />
                <Files files={product.files} />
                <Binded bindings={product.bindedProducts} />
            </div>
        );
    }
}
Product.contextType = AppContext;

export default Product;
