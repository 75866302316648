import React from 'react';

import { AuthService } from './authService';
import { TranslateService } from './translateService';
import { RequestService } from './requestService';

export const AppContext = React.createContext({
    authService: AuthService,
    translateService: TranslateService,
    requestService: RequestService
});
