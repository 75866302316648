import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppContext } from '../../services/context';

import appTranslate from '../../appTranslate.json';

import './Main.scss';

import TextField from '../../components/form/TextField';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            error: ''
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.user.login
        );
    };

    tryToLogin = () => {
        this.setState({ error: this.T('error') });
    };

    render() {
        return (
            <div className='_page'>
                <div className='_modal _modal--small modal-login'>
                    <TextField
                        value={this.state.login}
                        handleChange={value => this.setState({ login: value })}
                        label='Login'
                        type='text'
                        fieldName='login'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <TextField
                        value={this.state.password}
                        handleChange={value =>
                            this.setState({ password: value })
                        }
                        label={this.T('password')}
                        type='password'
                        fieldName='password'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <button
                        className='_button-light'
                        onClick={() => this.tryToLogin()}
                    >
                        {this.T('loginButton')}
                    </button>
                    <div>{this.state.error}</div>
                </div>
            </div>
        );
    }
}
Main.contextType = AppContext;

export default Main;
