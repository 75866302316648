import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import './Nav.scss';

// import $ from 'jquery';

import appTranslate from '../../appTranslate.json';

import logoImg from '../../assets/logo/logo_ge.png';
import { AppContext } from '../../services/context';

class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: false
        };
    }

    toggleNav = () => {
        this.setState({ navOpen: !this.state.navOpen });
    };

    closeNav = () => {
        this.setState({ navOpen: false });
    };

    logout = () => {
        console.log('logout');
        this.context.authService
            .tryToLogout()
            .then(() => {
                console.log('logout success');
            })
            .catch(error => {
                console.log('logout error');
                console.log(error);
            });
    };

    setLanguage = lang => {
        this.context.translateService.setLanguage(lang);
        console.log(this.context.translateService.getTranslation(appTranslate.nav.catalogue));
    };

    render() {
        return (
            <nav className='nav-main'>
                <div className='nav-main__middle'>
                    <div className='nav-main__logo'>
                        <img src={logoImg} className='_nav__left__logo' alt='GE Healthcare logo' />
                    </div>
                    <div className='nav-main__links'>
                        <Link to='/catalogue' onClick={this.closeNav}>
                            {this.context.translateService.getTranslation(appTranslate.nav.catalogue)}
                        </Link>

                        <Link to='/login' onClick={this.closeNav}>
                            {this.context.translateService.getTranslation(appTranslate.nav.login)}
                        </Link>
                    </div>
                    <div className='nav-main__request'>
                        <div
                            className='nav-main__request-button'
                            onClick={() => this.context.requestService.setModal(true)}
                        >
                            <div className='nav-main__request-icon'>list_alt</div>
                            {this.context.requestService.getProductsCount() > 0 ? (
                                <div className='nav-main__request-indicator'>
                                    {this.context.requestService.getProductsCount()}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='nav-main__language'>
                        <div
                            className={`nav-main__language-select ${
                                this.context.translateService.currentLanguage() === 'pl' ? 'active' : ''
                            }`}
                            onClick={() => this.context.translateService.setLanguage('pl')}
                        >
                            PL
                        </div>
                        <div className='nav-main__language-divider' />
                        <div
                            className={`nav-main__language-select ${
                                this.context.translateService.currentLanguage() === 'en' ? 'active' : ''
                            }`}
                            onClick={() => this.context.translateService.setLanguage('en')}
                        >
                            EN
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}
Nav.contextType = AppContext;

export default Nav;
