import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appConfig from '../../appConfig.json';
import appTranslate from '../../appTranslate.json';

import { AppContext } from '../../services/context';

import './ProductListItem.scss';

class RequestModal extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.request.modal);
    };

    render() {
        const e = this.props.item;
        const photo = e.photos.find(ph => ph.isMain);
        return (
            <div className='request-product-list-item__wrapper'>
                <div className='request-product-list-item__photo'>
                    {photo ? (
                        <img
                            src={appConfig.photoUrl + photo.filename}
                            className='request-product-list-item__photo-img'
                            alt=''
                        />
                    ) : (
                        <div className='request-product-list-item__photo-empty'>insert_photo</div>
                    )}
                </div>
                <div className='request-product-list-item__details'>
                    <div className='request-product-list-item__name'>{this.T(e.productName)}</div>
                    <div className='request-product-list-item__ean'>{e.ean}</div>
                    <div className='request-product-list-item__quantity'>
                        <input
                            type='text'
                            value={e.quantity}
                            onChange={event =>
                                this.context.requestService.setProductQuantity(e.productId, event.target.value)
                            }
                            onBlur={() => this.context.requestService.onProductBlur(e.productId)}
                        />
                    </div>
                </div>
                <div className='request-product-list-item__remove'>
                    <div
                        className='request-product-list-item__remove-button'
                        onClick={() => {
                            this.context.requestService.removeProductFromRequest(e.productId);
                        }}
                    >
                        delete
                    </div>
                </div>
            </div>
        );
    }
}
RequestModal.contextType = AppContext;

export default RequestModal;
