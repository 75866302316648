import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appConfig from '../../../appConfig.json';
import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

import './ProductElement.scss';

class ProductList extends Component {
    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.start);
    };

    render() {
        const element = this.props.element;

        const photo = element.photos.find(e => e.isMain);

        return (
            <div className='product-list-element__wrapper'>
                <div className='product-list-element__photo'>
                    {photo ? (
                        <img
                            src={appConfig.photoUrl + photo.filename}
                            className='product-list-element__photo-img'
                            alt=''
                        />
                    ) : (
                        <div className='product-list-element__photo-empty'>insert_photo</div>
                    )}
                </div>
                <div className='product-list-element__name'>{this.T(element.productName)}</div>
                <div className='product-list-element__ean'>{element.ean}</div>
                <div className='product-list-element__buttons'>
                    {this.context.requestService.isProductInRequest(element.productId) ? (
                        <button
                            className='product-list-element__button'
                            onClick={() => {
                                this.context.requestService.removeProductFromRequest(element.productId);
                            }}
                        >
                            {this.T('removeFromRequest')}
                        </button>
                    ) : (
                        <button
                            className='product-list-element__button'
                            onClick={() => {
                                this.context.requestService.addProductToRequest(element);
                            }}
                        >
                            {this.T('addToRequest')}
                        </button>
                    )}
                    <Link className='product-list-element__button' to={`/catalogue/product/${element.productId}`}>
                        {this.T('more')}
                    </Link>
                </div>
            </div>
        );
    }
}
ProductList.contextType = AppContext;

export default ProductList;
