import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';

import { AppContext } from '../../../../services/context';

import './Binded.scss';

class Files extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: {
                type: null,
                main: null,
                secondary: null
            }
        };
    }

    open = (level, id) => {
        this.setState(prev => ({
            open: {
                ...prev.open,
                [level]: prev.open[level] === id ? null : id
            }
        }));
    };

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.catalogue.product.details
        );
    };

    render() {
        const bindings = this.props.bindings;

        return (
            <div className='_catalogue-product__bindings'>
                {bindings.map(bg => {
                    if (bg.categoryTree.length === 0) {
                        return null;
                    }
                    const bgOpen = this.state.open.type === bg.productBindingTypeId;
                    return (
                        <div className='_catalogue-product__bindings-type'>
                            <div
                                className='_catalogue-product__bindings-type-element'
                                onClick={() => this.open('type', bg.productBindingTypeId)}
                            >
                                <div className='_catalogue-product__bindings-type-element-label'>
                                    {this.T(bg.label)}
                                </div>
                                <div className='_catalogue-product__bindings-type-element-icon'>
                                    {bgOpen ? 'expand_less' : 'expand_more'}
                                </div>
                            </div>
                            {bgOpen
                                ? bg.categoryTree.map(cm => {
                                      let cmOpen = this.state.open.main === cm.categoryId;

                                      return (
                                          <div className='_catalogue-product__category-main'>
                                              <div
                                                  className='_catalogue-product__category-main-element'
                                                  onClick={() => this.open('main', cm.categoryId)}
                                              >
                                                  <div className='_catalogue-product__category-main-element-label'>
                                                      {this.T(cm.label)}
                                                  </div>
                                                  <div className='_catalogue-product__category-main-element-icon'>
                                                      {cmOpen ? 'expand_less' : 'expand_more'}
                                                  </div>
                                              </div>
                                              {cmOpen
                                                  ? cm.categories.map(c => {
                                                        let cOpen = this.state.open.secondary === c.categoryId;

                                                        return (
                                                            <div className='_catalogue-product__category-secondary'>
                                                                <div
                                                                    className='_catalogue-product__category-secondary-element'
                                                                    onClick={() => this.open('secondary', c.categoryId)}
                                                                >
                                                                    <div className='_catalogue-product__category-secondary-element-label'>
                                                                        {this.T(c.label)}
                                                                    </div>
                                                                    <div className='_catalogue-product__category-secondary-element-icon'>
                                                                        {cOpen ? 'expand_less' : 'expand_more'}
                                                                    </div>
                                                                </div>
                                                                {cOpen ? (
                                                                    <div className='_catalogue-product__products'>
                                                                        {c.products.map(p => {
                                                                            let photo = p.photos.find(ph => ph.isMain);
                                                                            return (
                                                                                <div className='_catalogue-product__product-element'>
                                                                                    <div className='_catalogue-product__product-image'>
                                                                                        {photo ? (
                                                                                            <img
                                                                                                src={
                                                                                                    appConfig.photoUrl +
                                                                                                    photo.filename
                                                                                                }
                                                                                                alt=''
                                                                                            />
                                                                                        ) : (
                                                                                            <div className='_catalogue-product__product-image-empty'>
                                                                                                insert_photo
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='_catalogue-product__product-details'>
                                                                                        <div className='_catalogue-product__product-name'>
                                                                                            {this.T(p.productName)}
                                                                                        </div>
                                                                                        <div className='_catalogue-product__product-ean'>
                                                                                            {p.ean}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='_catalogue-product__product-buttons'>
                                                                                        <Link
                                                                                            className='_button-light small'
                                                                                            to={`/catalogue/product/${
                                                                                                p.productId
                                                                                            }`}
                                                                                        >
                                                                                            {this.T('show')}
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })
                                                  : null}
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}
            </div>
        );
    }
}
Files.contextType = AppContext;

export default Files;
