import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {
    render() {
        if (this.props.visible === false) {
            return <div />;
        }
        return (
            <div className='_loader-mask'>
                <div className={`_loader-mask__background ${this.props.greyBg ? '_bg-grey' : ''}`} />
                <div className='_loader-mask__content'>
                    <div className='_loader-mask__spinner'>
                        <div className='_loader-mask__spinner__element' />
                        <div className='_loader-mask__spinner__element' />
                        <div className='_loader-mask__spinner__element' />
                    </div>
                    <div className='_loader-mask__label'>{this.props.label}</div>
                </div>
            </div>
        );
    }
}

export default Loader;
